import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const RugbySequicentenary: React.FC<PageProps> = ({ data }) => (
  <Layout
    removeFooterMargin
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/rugby150/header-alt.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Rugby Sequicentenary"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase drop-shadow-[1px_1px_5px_rgba(0,0,0,.5)] shadow-black">
            <span>Rugby</span>
            <br />
            <span className="text-primary">Sesquicentenary</span> Book
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Rugby Sequicentenary Book" />

    <div className="container pt-14 pb-8">
      <img src="/images/ruby-150-book.jpeg" className="mb-5" />
    </div>

    <script
      type="text/JavaScript"
      src="//app.etapestry.com/hosted/eTapestry.com/etapEmbedResponsiveResizing.js"
    ></script>
    <iframe
      id="etapIframe"
      style={{ border: "none", width: "100%", minHeight: 1400 }}
      src="https://app.etapestry.com/onlineforms/TheRoyalBelfastAcademy/rugby150book.html"
    ></iframe>
  </Layout>
)

export default RugbySequicentenary
